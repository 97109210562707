export default [
   {Leaders:[
       {
           Name:"Tushar Gupta",
           Post:"Captain",
           LinkedIn:"",
           Gmail:"",
           Insta:"",
           Photo:""
       },
       {
        Name:"Abu Bakr",
        Post:"Vice-Captain",
        LinkedIn:"",
        Gmail:"",
        Insta:"",
        Photo:""
       },
       {
        Name:"Mohd Adnan",
        Post:"Team Manager",
        LinkedIn:"",
        Gmail:"",
        Insta:"",
        Photo:""
       },
       {
        Name:"Farhan Khan",
        Post:"Treasurer",
        LinkedIn:"",
        Gmail:"",
        Insta:"",
        Photo:""
       }


    ]},
    {
     TechnicalMembers:[
         {
            Name:"Syed Taimoor Ali",
            Post:"Chassis",
            LinkedIn:"",
            Gmail:"",
            Insta:"",
            Photo:""
         },
         {
            Name:"Mirza Ahmed Beg",
            Post:"Suspension",
            LinkedIn:"",
            Gmail:"",
            Insta:"",
            Photo:""
         },
         {
            Name:"Tushar Gupta",
            Post:"",
            LinkedIn:"",
            Gmail:"",
            Insta:"",
            Photo:""
         },
         {Name:"Hammad Qamar",
         Post:"..",
         LinkedIn:"",
         Gmail:"",
         Insta:"",
         Photo:""},
         {
            Name:"Arquam Hashim Siddique",
            Post:"",
            LinkedIn:"",
            Gmail:"",
            Insta:"",
            Photo:""
         },
         {
            Name:"Navneet Kumar",
            Post:"..",
            LinkedIn:"",
            Gmail:"",
            Insta:"",
            Photo:""
         }
         ,
         {
            Name:"Kuber",
            Post:"",
            LinkedIn:"",
            Gmail:"",
            Insta:"",
            Photo:""
         }
         ,
         {
            Name:"Arsalan Siddiqui",
            Post:"",
            LinkedIn:"",
            Gmail:"",
            Insta:"",
            Photo:""
         }
         ,
         {
            Name:"Aas Mohammad",
            Post:"",
            LinkedIn:"",
            Gmail:"",
            Insta:"",
            Photo:""
         }
         ,
         {
            Name:"Imad Bin Asad",
            Post:"",
            LinkedIn:"",
            Gmail:"",
            Insta:"",
            Photo:""
         }

         ,
         {
            Name:"Talha Ahmad Zia",
            Post:"",
            LinkedIn:"",
            Gmail:"",
            Insta:"",
            Photo:""
         }
     ]},
     {
        ManagementMembers:[
            {
               Name:"Mohd Adnan",
               Post:"Manager",
               LinkedIn:"",
               Gmail:"",
               Insta:"",
               Photo:""
            },
            {
               Name:"HAQ Hamza Ahmad khan",
               Post:"Business Lead",
               LinkedIn:"",
               Gmail:"",
               Insta:"",
               Photo:""
            },
            {
               Name:"Arsalan Sartaj",
               Post:"",
               LinkedIn:"",
               Gmail:"",
               Insta:"",
               Photo:""
            },
            {
               Name:"Abdul Samad",
               Post:"",
               LinkedIn:"",
               Gmail:"",
               Insta:"",
               Photo:""
            },
            {
               Name:"Imad Asif",
               Post:"",
               LinkedIn:"",
               Gmail:"",
               Insta:"",
               Photo:""
            },
            {
               Name:"Syed Mikayel",
               Post:"",
               LinkedIn:"",
               Gmail:"",
               Insta:"",
               Photo:""
            },
            
        ]},


]
export default [
    "Press Release FS Austria 2023.pdf",
    "Press Release_Formula Bharat 2023.pdf",
    "Press Release_Efficycle 2022.pdf",
    "Press Release_Formula Imperial.pdf",
    "Press Release Pi-EV 2022 (3).pdf",
    "Press Release - SAE ZHCET Installation Ceremony.pdf",
    

    
    
    
    
    
    
    
    
]
export default [
    {
        Section0 :[
            
                { img: "https://drive.google.com/uc?id=1wqldCnN8HLEQYczt6Sy7j33kGcF7Clje" },
                { img: "https://drive.google.com/uc?id=1g9gNgmn8jXmJivpCNb98CLSObvoZ5zOM" },
                { img: "https://drive.google.com/uc?id=1ctxAvZS1dQ0JLyKih0WD1n1lk0_UPDQ7" },
                { img: "https://drive.google.com/uc?id=1aa7ipanXRDzIte33jhqcMZN3Am13tECh" },
                { img: "https://drive.google.com/uc?id=1r_yLlxB0FW2pEbvJp5gjTnf_uftHnVOd" },
                { img: "https://drive.google.com/uc?id=1ikvqyzifz33AyDO-NtsPeB3bzydpZh3L" },
                { img: "https://drive.google.com/uc?id=1YQ9KPfoZV2EseqDuDppp8MBAjSQ3NPPs" },
                { img: "https://drive.google.com/uc?id=1JiGRb_DkQciK_vZyo5ILu7-37IaWMVBM" },
                { img: "https://drive.google.com/uc?id=152A6jnPX6MFZxG5x6FkBU_X3pmwN-gV2" },
                { img: "https://drive.google.com/uc?id=1KGeTsV32Axw2ETUFdyktvHJ237OqyVQN" },
              
              
            { img: "https://drive.google.com/uc?id=19AQH_r5w42KJa-YJHm4LoxIbl-3Vs0PW" },
            { img: "https://drive.google.com/uc?id=1sHwUJf5GfSnwhe1yzHHSNvOYX3dpbJ9Z" },
            { img: "https://drive.google.com/uc?id=1EFY8n82-egMuUq-P3-kQdt4daHYetwl2" },
            { img: "https://drive.google.com/uc?id=15lisoWtifRtYHxf-Y1A9JkJHn90SX2sg" },
            { img: "https://drive.google.com/uc?id=1oJtjmGVsVNeOnp_pnbw2LseZjGAbamEs" },
            { img: "https://drive.google.com/uc?id=1o6wJTwOWfwzLg3Z0vzrUzmCAdn3cA8Rg" },
            { img: "https://drive.google.com/uc?id=1yMtuo9G7O2yM7CI3JdUdggQ0OICIvT7C" },
            { img: "https://drive.google.com/uc?id=1gZ-4AF1z_ScQGVrSxq5JnJUgB8Ry_4Gv" },
            { img: "https://drive.google.com/uc?id=1e3Ruj-6MGrL8np3AHkKLBTMze9fBT9Ya" },
            { img: "https://drive.google.com/uc?id=19YReSmSyMQLv261UeyaIwqi_Fhhasreh" },
            { img: "https://drive.google.com/uc?id=1lc1fujsbCkKk4NEVesOygs4I3oAMXjse" },
            { img: "https://drive.google.com/uc?id=1XLwamHOK253WupJ57K1vcvC01try34q7" },
            { img: "https://drive.google.com/uc?id=1cB70HTikJB24tFI9Gouft-gLvONMItbw" },
            { img: "https://drive.google.com/uc?id=1lPRmkzr0w1vqUFn8EatqizYK09LeqADg" },
            { img: "https://drive.google.com/uc?id=1Syp9PAf_iu4hM0k6y05RS7Aeytggp3nb" },
            { img: "https://drive.google.com/uc?id=1SxuUs2fJrh7V_IdSHP24fNw4IXQpU1bI" },
            { img: "https://drive.google.com/uc?id=1gM9-uii_h5ZS1B47uWTbzhyzW44ykexu" },
            { img: "https://drive.google.com/uc?id=1krpAN719Ka1_MLdWcKA2SGcuHsC2R_RE" },
            { img: "https://drive.google.com/uc?id=135R4-VD5LOroruSiA_RsdvUL5CG6iJJa" }
          
          
          
          
          
        ],
        Section1:[
            {img: "https://drive.google.com/uc?id=10l7qqTIRDom7eKdSx45Q-zRaDt2Erpg9"},
            {img: "https://drive.google.com/uc?id=1B8-PYMrfhYtc4c4W9uGqBgGBH2VvQadE"},
            {img: "https://drive.google.com/uc?id=1CG8ziiGLraM9QEFc_zRjQ6dXjkBNEw96"},
            {img: "https://drive.google.com/uc?id=1KjWViUjccJYPSwc46vaFzeQSTVZ2S_9t"},
            {img: "https://drive.google.com/uc?id=1ORPl9_FjBMtCCqM-_AaCFTbhxNFuSEqr"},
            {img: "https://drive.google.com/uc?id=1U1Mrycwg-Ni4aFlEYtNMCpBEZb_zw9hx"},
            {img: "https://drive.google.com/uc?id=1UBtDTj3iLGMeDPM8LP2tErG2n7odP6rC"},
            {img: "https://drive.google.com/uc?id=1WVw8umon7H1gQMGEtzVfewvhLZJo9wds"},
            {img:" https://drive.google.com/uc?id=1XQbf5nqAkToah5-VnoIjJxjNDuEpymDx"},
            {img: "https://drive.google.com/uc?id=1coJRxt8n7wM3iTgEcouB2TJyxriMX8T1"},
            {img: "https://drive.google.com/uc?id=1d4bi7-Ap0MIlgoc-Ky0IybM6AIJnwflb"},
            {img:" https://drive.google.com/uc?id=1eVimzTKhaptUjR_Ow4wy_7xNUAGLyFBh"},
            {img: "https://drive.google.com/uc?id=1iIIVzzxhRw-6oq7BbXVI_aOCffXVdEo0"},
            {img: "https://drive.google.com/uc?id=1pzt5Et4S0NOoWCjDWUBMvdqf14SPg1Gx"},
            {img:" https://drive.google.com/uc?id=1yG0eL9AZ-q3-C5KerQd0K3OPs8Q0KHMd"}
        ],
        Section2:[
            { img: 'https://drive.google.com/uc?id=1jdrlkU6nSs2X35R-pSDQcfH8womLtb1R' },
            { img: 'https://drive.google.com/uc?id=11nHeRpRJtUJc1QgpIyH0NqZccAArCrzQ' },
            { img: 'https://drive.google.com/uc?id=1FVu2WH2Mp1FLq65PBHWiIA2eUA98SqlJ' },
            { img: 'https://drive.google.com/uc?id=1w0pDOTMmkI0aCqb3P7giQDe9Jt0l3fJa' },
            { img: 'https://drive.google.com/uc?id=1rUzbKkiTrYGQn68fYt0poIe-wjHpfR9A' },
            { img: 'https://drive.google.com/uc?id=1a1P45s-13TCtKsveJBfeZUsNE6ZoDaas' },
            { img: 'https://drive.google.com/uc?id=1M-D1SXgexEIjTuuL4P1ncpc2F37kMocO' },
            { img: 'https://drive.google.com/uc?id=1ZoFHVMBF_Kel1VBgCvPN8d1kMhvjfbFE' },
            { img: 'https://drive.google.com/uc?id=1ZUORmgx4FKLLl5OL4fAEqLochbnkq2gQ' },
            { img: 'https://drive.google.com/uc?id=1zzKEvEgvOtZlOIzow-Dq5BhJxt2FwlHJ' },
            { img: 'https://drive.google.com/uc?id=1zPMs3qj4gUbxEig7Vr3H3X6IIDLLQhW8' },
            { img: 'https://drive.google.com/uc?id=1wX9GeXQf8lT5MK4n_2xPpdQ5T6S75-Yo' },
            { img: 'https://drive.google.com/uc?id=18qJDFp_lOLOvCrM1hNUN9EMyjNTbmXxD' },
            { img: 'https://drive.google.com/uc?id=14AYyDaa2jFxH7eEqbHRvqFzumP6DELl_' },
            { img: 'https://drive.google.com/uc?id=1hWFYm5jzPrIShA0el4AhL1lM7b4CaboJ' }

        ],
        Section3:[
            { img: "https://drive.google.com/uc?id=1UBSG_OWFq3Sg5Aw5YDqheaHP6EBWlz41" },
            { img: "https://drive.google.com/uc?id=1XdDF-JTFmt6XsXJ5wruSjqRHTk9LKZtZ" },
            { img: "https://drive.google.com/uc?id=1Ncqjw9-BY99I7BtXbmNzZ_996ZlO6Ht1" },
            { img: "https://drive.google.com/uc?id=1bGTMp0YvfN2-NcgUJws0-aMdix7FiOMC" },
            { img: "https://drive.google.com/uc?id=1ahU5sswnqj50nNWB_lBI56jlookkjd7x" },
            { img: "https://drive.google.com/uc?id=1h9i8dhlvUCd06RRiXTiTQG14M88CCcNp" },
            { img: "https://drive.google.com/uc?id=1tiNZrFS55wMYrbBMxFbVIZcQ1mKrrD_W" },
            { img: "https://drive.google.com/uc?id=1TlpAV-vzJujSJZliakgpyvy7G3XIu4L-" },
            { img: "https://drive.google.com/uc?id=1NN5P-c4uWvldXs9aLNVGQvkQ4_zZj5Lc" },
            { img: "https://drive.google.com/uc?id=1qKeVQa5nLC40Eiiy5Vm3FKer0q5_aUNt" },
            { img: "https://drive.google.com/uc?id=1776c6HTS1aIDzkqpHDalPRdmhFQVlqjj" },
            { img: "https://drive.google.com/uc?id=1EwK1LX4dEVQls2hkS2U_sKF9o18FZSxF" },
            { img: "https://drive.google.com/uc?id=1OJuVYFD-SnT6bMjvRZfDPEdvCvop1X75" },
            { img: "https://drive.google.com/uc?id=1aMZqSeD1y-CJM9SVta7Bx70XB8-GPr0I" },
            { img: "https://drive.google.com/uc?id=1guftf_MYHIEE2Z14WuqfJzWV1ad_w0FM" },
            { img: "https://drive.google.com/uc?id=1WtS23Vrd-sYuIncLA_NA7Xt8v5i54_v8" }

        ],
        Section4:[
            
                {img: "https://drive.google.com/uc?id=1kTo7gcW4O3OxXpwM2pxVTZ2U0tpdkR_2"},
                {img: "https://drive.google.com/uc?id=1dRngYzS8K91OQk9l9Kf-e9Sd2RsYWo19"},
                {img: "https://drive.google.com/uc?id=1__l_yy92HkDcWQIh5Is7vCjihlLs6Ung"},
                {img: "https://drive.google.com/uc?id=1UuNXD4WqeKX0XY0YMVi7uqkMUQ2VwoTB"},
                {img: "https://drive.google.com/uc?id=1UK-kQ5na0p2mCZyl9LsYDdBMzmZc5ms-"},
                {img: "https://drive.google.com/uc?id=1QpsIFzrLohqpnug697Njw1jNMRkOax7Z"},
                {img: "https://drive.google.com/uc?id=1OjmugU8iy4byRwo5ojvFFFhzCM8eAG6w"},
                {img: "https://drive.google.com/uc?id=1OCYgp38xc5k_x_J-ldAnBJDIOenVaYBE"},
                {img: "https://drive.google.com/uc?id=17hmPpP-0j4wWxR9UESufRy7rdgR4RURT"},
                {img: "https://drive.google.com/uc?id=1sAjkYdQRIi2NHYzMWRVRziPJY9ttSLx0"},
                {img: "https://drive.google.com/uc?id=1pgvUjNw_IKNBg7Dw1ULD0wVv5Dx1fAjO"},
                {img: "https://drive.google.com/uc?id=1mIHonx0Al8Ed-jhUeIShr3-BTkbIsb3S"},
                {img: "https://drive.google.com/uc?id=1lV-k0FrYTpD18PaGAAG8__dL0Si3iSlU"},
                {img: "https://drive.google.com/uc?id=1khjGRTik0VuY9F4w1o1qlKF22LkqKp0R"},
                {img: "https://drive.google.com/uc?id=1kDsBT9Rw8zj9RbAeqekw5G0ei1_t_xSo"},
                {img: "https://drive.google.com/uc?id=1giTW8LgjIPfI24ezLv6Ps-2-2QSkodTw"},
                {img: "https://drive.google.com/uc?id=1gUVHskyFqjev91ixzexHjteT5p9ph-oc"},
                {img: "https://drive.google.com/uc?id=1UUBtYp_rPN7ITE_NrI72Nx9NeqyfGKER"},
                {img: "https://drive.google.com/uc?id=1FAt3SaFPDeK1mFfsTVM4o2TRUg9opBgu"},
                {img: "https://drive.google.com/uc?id=1AZQf6j_aYtvPwDpYPT1iffaMLUYOhIe8"},
                {img: "https://drive.google.com/uc?id=19oShxeCq4ufrDigvMvRgAuW6L-Cj6dxU"},
                {img: "https://drive.google.com/uc?id=13Pa7c6xuYPBUUHgzwEZJm9voecNBMjF0"}
            
            

        ],
        Section5:[
            
                {img: "https://drive.google.com/uc?id=1vfAjCAuDeLLBlyYhoT8xqDUfpC2jzPIr"},
                {img: "https://drive.google.com/uc?id=19mBD5hvuVTQv7PhObjDSHAIKcUutbWX2"},
                {img: "https://drive.google.com/uc?id=1A_J__P8nRpdNLB_godLTBBiJTQb-mdj4"},
                {img: "https://drive.google.com/uc?id=1_-C7aJ8sk7Vwdab2ajFZAwaCMVGhJ4RK"},
                {img: "https://drive.google.com/uc?id=1f8SpGSv3iuIDYvcoROxrwPisrpdaBFcR"},
                {img: "https://drive.google.com/uc?id=1vFi9yAB4PBWyt7_50BFsCW0I7Nz_YC9d"},
                {img: "https://drive.google.com/uc?id=1pQvdjWmhDRZ-1nmhEA0IdgkhuG1gfkg6"},
                {img: "https://drive.google.com/uc?id=1fPACuC-MOjTZD2ly90hf88iOT45jSWfS"},
                {img: "https://drive.google.com/uc?id=1INNS99cD1eet1d2OIij_BaEGxKAdT1aR"},
                {img: "https://drive.google.com/uc?id=1HaKBBlCA3QyvNB2fAQUPoj2JRtE4D2VI"},
                {img: "https://drive.google.com/uc?id=1Dwn3pa94xUtRp1jwvcVZp8G8YiQytH1f"},
                {img: "https://drive.google.com/uc?id=19zJc1o4VE5WNs7RivBqefgODmI-K9puc"},
                {img: "https://drive.google.com/uc?id=19PvaRihz_OLv4RaVdgmhoyvEvWqpvPa4"},
                {img: "https://drive.google.com/uc?id=1-0FKOZS5cx5TJxZ37kyFj-9pv6qoBIME"}
            
            

        ],
        Section6:[
            {img: "https://images.pexels.com/photos/546819/pexels-photo-546819.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"},
            {img: "https://images.pexels.com/photos/546819/pexels-photo-546819.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"},
            {img: "https://images.pexels.com/photos/546819/pexels-photo-546819.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"},
            {img: "https://images.pexels.com/photos/546819/pexels-photo-546819.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"}

        ],
        Section7:[
            {img: "https://images.pexels.com/photos/546819/pexels-photo-546819.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"},
            {img: "https://images.pexels.com/photos/546819/pexels-photo-546819.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"},
            {img: "https://images.pexels.com/photos/546819/pexels-photo-546819.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"},
            {img: "https://images.pexels.com/photos/546819/pexels-photo-546819.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"}

        ],
        Section8:[
            {img: "https://images.pexels.com/photos/546819/pexels-photo-546819.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"},
            {img: "https://images.pexels.com/photos/546819/pexels-photo-546819.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"},
            {img: "https://images.pexels.com/photos/546819/pexels-photo-546819.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"},
            {img: "https://images.pexels.com/photos/546819/pexels-photo-546819.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"}

        ],
        Section9:[
            {img: "https://images.pexels.com/photos/546819/pexels-photo-546819.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"},
            {img: "https://images.pexels.com/photos/546819/pexels-photo-546819.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"},
            {img: "https://images.pexels.com/photos/546819/pexels-photo-546819.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"},
            {img: "https://images.pexels.com/photos/546819/pexels-photo-546819.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"}

        ],
        Section10:[
            {img: "https://images.pexels.com/photos/546819/pexels-photo-546819.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"},
            {img: "https://images.pexels.com/photos/546819/pexels-photo-546819.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"},
            {img: "https://images.pexels.com/photos/546819/pexels-photo-546819.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"},
            {img: "https://images.pexels.com/photos/546819/pexels-photo-546819.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"}

        ],

        
    }
]
export default [{
    Images:[{img:"Picture1.png",link:"https://codefrontend.com/reactjs-redirect-to-url/#:~:text=Navigating%20to%20an%20external%20page%20in%20React&text=To%20navigate%20to%20another%20page,active%20URL%20to%20navigate%20window."},
            {img:"Picture2.jpg",link:""},
            {img:"Picture3.jpg",link:""},
            {img:"Picture4.jpg",link:""},
            {img:"Picture5.jpg",link:""},
            {img:"Picture6.jpg",link:""},
            {img:"Picture7.png",link:""},
            {img:"Picture8.jpg",link:""},
            {img:"Picture10.png",link:""},
            {img:"Picture11.png",link:""},
            {img:"Picture12.png",link:""},
            {img:"Picture13.png",link:""},
            {img:"Picture14.png",link:""},
            {img:"Picture15.png",link:""},
            {img:"Picture16.png",link:""},
            {img:"Picture17.png",link:""},
            {img:"Picture18.jpg",link:""},
            {img:"Picture19.jpg",link:""},
            {img:"Picture20.jpg",link:""},
            {img:"Picture21.jpg",link:""},
            
            
            
            ]
}]
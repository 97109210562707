import React from 'react'
import './Newsletter.css';


const Newsletter = () => {
  return (
    <div style={{paddingTop:"400px"}}>

        <div >
            
       
        </div>
    </div>
  )
}

export default Newsletter
export default [
    {
        Title:"TGW-10.0",
        Desc:"2022",
        id:"tgw/10.0",
        Image:"effi10.0.jpg"


    },
    {
        Title:"TGW-9.0",
        Desc:"2021",
        id:"tgw/9.0",
        Image:"effi9.0.png"


    }
    
]
export default [
    {
        Title:"ZFR-1.0",
        Desc:"2016",
        id:"zfr-cv/1.0",
        Image:"zfr1.0..jpg"

    },
    {
        Title:"ZFR-2.0",
        Desc:"2017",
        id:'zfr-cv/2.0',
        Image:"zfr2.0.jpg"


    },
    {
        Title:"ZFR-3.0",
        Desc:"2018",
        id:"zfr-cv/3.0",
        Image:"zfr3.0.jpg"

    },
    {
        Title:"ZFR-4.0",
        Desc:"2019",
        id:"zfr-cv/4.0",
        Image:"zfr4.0.jpg",
    },

    
    {
        Title:"ZFR-5.0",
        Desc:"2019",
        id:"zfr-cv/5.0",
        Image:"Zfrbg.jpg"

    }
]